<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('IgnoredCustomersCreate')"
    @edit="(id) => onEdit('IgnoredCustomersEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'IgnoredCustomers',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'IgnoredCustomers',
      tableCaption: 'Клиенты',
      breadcrumbs: [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Клиенты, по которым не контролировать долги',
        },
      ],
      tableHeaders: [
        { text: 'Имя клиента', alias: 'customerName', order: 'customerName' },
				{ text: '', alias: 'actions', order: 'actions' },
      ],
    };
  },
};
</script>
